<template>
  <div class="meals">
    <div class="page-header">
      <h1>{{ $t('Meals Management') }} > {{ $t('Set Meals') }} > {{ $t('Add') }}</h1>
    </div>
    <div class="page-content">
      <div class="content-header">
        <h2>{{ $t('Add Set Meal') }}</h2>
      </div>
      <SetMealForm @action="addSetMeal"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Menu from '@/lib/menu';
import SetMealForm from '@/components/menu/SetMealForm.vue';

export default {
  name: 'AddSetMeal',
  components: {
    SetMealForm
  },
  methods:{
    async addSetMeal(form){
      try{
        const loginInfo = Common.getLoginInfo();
        const meal = await Menu.createMeal(this.apiUrl, form, loginInfo);
        this.$router.push('/set-meals');
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   langcode: state => state.langcode,
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style scoped lang="scss">
.content-header{
  display: flex;
  h2{
    flex: 1;
  }
  .action-button{
    margin-right: 20px;
  }
}
</style>
