import { render, staticRenderFns } from "./AddSetMeal.vue?vue&type=template&id=3a6f1ef9&scoped=true&"
import script from "./AddSetMeal.vue?vue&type=script&lang=js&"
export * from "./AddSetMeal.vue?vue&type=script&lang=js&"
import style0 from "./AddSetMeal.vue?vue&type=style&index=0&id=3a6f1ef9&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a6f1ef9",
  null
  
)

export default component.exports